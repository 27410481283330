import React from 'react';
import people from './img/people.png';
import ai from './img/AODG26844.png';
import Typist from 'react-typist';
import wh from '../src/img/whatsapp.png'

import './head.css';















const Head = () => (   
  <div style={{  marginTop:"-15px"}} className='h'>







  <div  className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <br/>  

      <div style={{ opacity:"0.8",borderRadius:"50px" , textAlign:"center" ,marginLeft:"auto",marginRight:"auto",marginTop:"auto",marginBottom:"-50px"}} className="hedertex" >


  <h1 style={{color:"white",textAlign:"center"}} className="gradient__text">DJ </h1>      
  <h1 style={{color:"white",textAlign:"center"}} className="gradient__text">Jordan Edri</h1>
  
  <Typist>

      <h2 style={{color:"#DCE775" ,textAlign:"center" ,fontWeight:"bolder"}} className="gradient__text">מעל 15 שנות ניסיון בעולם המוזיקה</h2>
    
  </Typist>

  </div>

  

    </div>

    <div style={{borderRadius:"0px",textAlign:"center",marginBottom:"-15px" }} className="gpt3__header-image">
 
    <img className='im' style={{borderRadius:"0px",textAlign:"center" }} src={ai} />

   



    </div>
    <div>


    </div>
  </div>
  </div>
);

export default Head;
