import { GlobalStyle } from "./globalStyles";
import { lazy, Suspense } from "react";
////import Screen  from './components/Screen';
import Head  from './Head';
import Gallery from './Gallery';
import styled from 'styled-components';
import { AccessibilityWidget } from 'react-accessibility';
import FloatingWhatsApp from 'react-floating-whatsapp'

const Styleapp = styled.div`

width: auto;
height: auto;


`
const Home = lazy(() => import("./Pages/Home"));
const Header = lazy(() => import("./components/Header/index"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop/index"));

function App() {
  return (
    <>
    <Styleapp>

      <Suspense fallback={null}>
      <ScrollToTop />
      <AccessibilityWidget />
      <FloatingWhatsApp />

      <GlobalStyle />
        <Header />
  

      
        <Head />
        <Gallery />
        
        <Home />

  
      

          
      </Suspense>
      </Styleapp>
    </>
  );
}

export default App;








/// background:url(${banner});
///background-repeat: no-repeat;
///background-attachment: fixed;
///background-position: center;
///background-size: cover;